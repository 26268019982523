'use strict'

export class PlayServer
{
    constructor() {
        this._active_sessions = [];
        this._ping_timer = -1;
        this._ping_timeout = 5000;
        this._game_server = "";

        this._test_server = null;

        this.handleSystemBlock = this.handleSystemBlock.bind(this);

        PubSub.subscribe("system.block", this.handleSystemBlock);
    }

    handleSystemBlock() {
        this.stopTimer();
    }

    parsePlay(data) {
        window.profile.versionNow(data['version']);
        window.serverTime = data['ts'];
    }

    playing(server, game_id, session_id) {
        mylogi("Playing " + game_id + " @ " + server);

        this._active_sessions.push({'server': server, 'game_id': game_id, 'session_id': session_id});

        if(this._ping_timer == -1) {
            this.startTimer();
        }

        window.profile.playingAt(game_id, server);

        var server_region = "us";
        if(server.includes("-eu")) {
            server_region = "eu"
        }
        if(server.includes("-eu2")) {
            server_region = "eu2"
        }
        if(server.includes("-ap")) {
            server_region = "ap"
        }
        $("#serverid").html(server_region);
    }

    routeServer(game_id, server) {
        mylogi("Rerouting game " + game_id + " to " + server);
        window.profile.playingAt(game_id, server);
    }

    async pingAll() {
        for (let i = 0; i < this._active_sessions.length; i++) {
            var s = this._active_sessions[i];

            if(this._test_server != null) {
                await $.get( this._test_server + "api/playing/"+s.game_id+"/"+s.session_id+"/"+window.Main.clicksnow+"/"+window.profile.getProfileID()+"/", this.parsePlay);
            }else{
                if(instant != '0') {
                    await $.get( s.server + "api/playing/"+instant+"/"+s.session_id+"/"+window.Main.clicksnow+"/"+window.profile.getProfileID()+"/", this.parsePlay);
                }else{
                    await $.get( s.server + "api/playing/"+s.game_id+"/"+s.session_id+"/"+window.Main.clicksnow+"/"+window.profile.getProfileID()+"/", this.parsePlay);
                }
            }
        }
        window.Main.checkOnlyTab();
    }

    async startDemo(game_id) {
        var server = this.getServer(game_id);
        var session_id = null;

        for (let i = 0; i < this._active_sessions.length; i++) {
            var s = this._active_sessions[i];

            if(s.game_id == game_id) {
                session_id = s.session_id;
                break;
            }
        }

        var aid = instant_aid.split("-").join("/");

        if(session_id != null) {
            if(this._test_server != null) {
                await $.get( this._test_server + "api/demo/"+instant+"/"+session_id+"/"+window.profile.getProfileID()+"/"+aid+"/"+instant_package+"/"+intant_activity+"/");
            }else{
                await $.get( server + "api/demo/"+instant+"/"+session_id+"/"+window.profile.getProfileID()+"/"+aid+"/"+instant_package+"/"+intant_activity+"/");
            }
        }else{
            setTimeout(() => {
                window.play_server.startDemo(game_id);
            }, 1000);
        }
    }

    async refocusGame(game_id) {
        var server = this.getServer(game_id);
        var session_id = null;

        for (let i = 0; i < this._active_sessions.length; i++) {
            var s = this._active_sessions[i];

            if(s.game_id == game_id) {
                session_id = s.session_id;
                break;
            }
        }

        if(session_id != null) {
            if(this._test_server != null) {
                await $.get( this._test_server + "api/refocus/"+game_id+"/"+session_id+"/"+window.profile.getProfileID()+"/");
            }else{
                await $.get( server + "api/refocus/"+game_id+"/"+session_id+"/"+window.profile.getProfileID()+"/");
            }
        }
    }

    async restartGame(game_id) {
        var server = this.getServer(game_id);
        var session_id = null;

        for (let i = 0; i < this._active_sessions.length; i++) {
            var s = this._active_sessions[i];

            if(s.game_id == game_id) {
                session_id = s.session_id;
                break;
            }
        }

        if(session_id != null) {
            if(this._test_server != null) {
                await $.get( this._test_server + "api/restart/"+game_id+"/"+session_id+"/"+window.profile.getProfileID()+"/");
            }else{
                await $.get( server + "api/restart/"+game_id+"/"+session_id+"/"+window.profile.getProfileID()+"/");
            }

            // reload page
            window.location.reload();
        }
    }

    startTimer() {
        this._ping_timer = setInterval(() => {
            this.pingAll();
        }, this._ping_timeout);
    }

    stopTimer() {
        if( this._ping_timer != -1 ) {
            clearInterval(this._ping_timer);
            this._ping_timer = -1;
        }
    }

    setDefaultServer(server) {
        this._game_server = server;
    }

    setTestServer(server) {
        this._test_server = server;
    }

    getServer(game_id) {
        if( window.force_server === true ) {
            return serverurl_play;
        }

        var last_played = window.profile.getLastPlayServer(game_id);
        if(last_played != null) {
            return last_played;
        }

        return this._game_server;
    }
}