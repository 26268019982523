'use strict';

import WifiOffIcon from '@mui/icons-material/WifiOff';
import { IconButton, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { createElement } from 'react';

const e = createElement;

export class UI
{
    constructor() {
        this._is_mobile = this.isMobileMode();
        this._windows = [];
        this._components = [];
        this._visible_windows = [];
        this._visible_toasts = [];
        this._free_listeners = [];
        this._free_listeners_toast = [];
        this._loading_window = false;
        this._windows_blocked = false;

        window.addEventListener('resize', this.onResize.bind(this), false);
    }

    blockWindows() {
        this._windows_blocked = true;
        this._free_listeners = [];

        for(var i=0; i<this._visible_windows.length; i++) {
            this._visible_windows[i].hide();
        }
        
        this._visible_windows = [];
    }

    hideWindow(id) {
        if(id in this._windows) {
            this._windows[id].hide();
        }
    }

    hideWindows() {
        this._free_listeners = [];

        for(var i=0; i<this._visible_windows.length; i++) {
            this._visible_windows[i].hide();
        }
        
        this._visible_windows = [];
    }

    isMobileMode() {
        return window.innerHeight > window.innerWidth;
    }

    onResize() {
        if(this._is_mobile != this.isMobileMode()) {
            this._is_mobile = this.isMobileMode();

            PubSub.publish("ui.resize", {mobile: this._is_mobile});
        }
    }

    runOnFree(func) {
        this._free_listeners.push(func);
        
        this.checkFreeQueue();
    }

    runOnFreeToast(func) {
        this._free_listeners_toast.push(func);

        this.checkFreeQueueToast();
    }

    checkFreeQueueToast() {
        if(this._free_listeners_toast.length > 0) {
            if(this._visible_toasts.length <= 0 && this._loading_window == false) {
                this._free_listeners_toast[0]();
                this._free_listeners_toast.splice(0, 1);
                this.checkFreeQueueToast();
            }
        }
    }

    checkFreeQueue() {
        if(this._free_listeners.length > 0) {
            if(this.isNoWindow()) {
                this._free_listeners[0]();
                this._free_listeners.splice(0, 1);
                this.checkFreeQueue();
            }
        }
    }

    shown(popup) {
        if(popup._dom_id == "toast") {
            this._visible_toasts.push(popup);
        }else{
            this._visible_windows.push(popup);
        }
    }

    hidden(popup) {
        var index = this._visible_windows.indexOf(popup);
        if (index !== -1) {
            this._visible_windows.splice(index, 1);
        }
        index = this._visible_toasts.indexOf(popup);
        if (index !== -1) {
            this._visible_toasts.splice(index, 1);
        }
        this.checkFreeQueue();
        this.checkFreeQueueToast();
    }

    isNoWindow() {
        var paying = false;
        if(window.pay != undefined && window.pay != null) {
            paying = window.pay.isPaying();
        }

        return this._visible_windows.length <= 0 && this._loading_window == false;
    }

    async showDialog(popupId, props={}, forced=false) {
        if( this._windows_blocked && forced == false) {
            return ;
        }

        this._loading_window = true;

        if(popupId in this._windows) {
            this._windows[popupId].show(props);
        }else{
            try{
                var popup = await import(`./popups/${popupId}.js`);
                var dialog = popup.init(popupId);
                this._windows[popupId] = dialog;
                
                dialog.show(props);
            }catch(error) {
                window.Main.handleWindowError(popupId, error)
            }
        }

        this._loading_window = false;
        this.checkFreeQueue();
        this.checkFreeQueueToast();
    }

    async showComponent(componentId, props={}) {
        if(componentId in this._components) {
            this._components[componentId].show(props);
        }else{
            try{
                var code = await import(`./components/${componentId}.js`);
                var component = code.init(componentId);
                this._components[componentId] = component;
                
                component.show(props);
            }catch(error) {
                window.Main.handleWindowError(componentId, error)
            }
        }
    }

    // show functions
    async showLoginPrompt() {
        await this.showDialog('signup-prompt');
    }

    async showMigrating(device) {
        await this.showDialog('migrate-window', {device: device}, true);
    }

    async showLogin(signup) {
        await this.showDialog('login-popup', {signup: signup});
    }

    async showOvertime(blocking=false) {
        await this.showDialog('overtime-window', {blocking: blocking});
    }

    async showLinkProgress() {
        await this.showDialog('link-progress');
    }

    async showLinkAccount() {
        this.runOnFree(() => { this.showDialog('link-account') });
    }

    async showToast(text) {
        this.runOnFreeToast(() => { this.showDialog('toast', {'text': text}, true); });
    }

    async showOvertimeTimer() {
        await this.showComponent('overtime-timer');
    }

    async showLoginFooter() {
        await this.showComponent('login-footer');
    }

    async showBookmark() {
        this.runOnFree(() => { this.showDialog('bookmark-popup') });
    }

    async showPaymentDone(payment, bonus) {
        this.runOnFree(() => { this.showDialog('payment-done-popup', {payment: payment, bonus: bonus})});
    }

    async showLobbyPopup(lobby_id) {
        this.runOnFree(() => { this.showDialog('lobby-popup', {lobby_id: lobby_id})});
    }

    async showiOSPWA() {
        this.runOnFree(() => { this.showDialog('pwa-ios-popup')});
    }

    async showSelectPayment(callback, market_url=null) {
        mylogi("selecting with url", market_url);
        this.runOnFree(() => { this.showDialog('payment-select', {callback: callback, market_url: market_url}) });
    }

    async toggleKeyboard() {
        if(window.ui.isNoWindow()) {
            this.runOnFree(() => { this.showDialog('keyboard-popup')});
        }else{
            this.hideWindow('keyboard-popup');
        }
    }

    async showKeyboard() {
        if(window.ui.isNoWindow()) {
            this.runOnFree(() => { this.showDialog('keyboard-popup')});
        }
    }

    async showRewardedTest(callback, onreward) {
        this.runOnFree(() => { this.showDialog('rewarded-test', {onClose: callback, onReward: onreward})});
    }

    async showInterstitialTest(callback) {
        this.runOnFree(() => { this.showDialog('interstitial-test', {onClose: callback})});
    }

    async showMaintenanceWindow() {
        this.runOnFree(() => { this.showDialog('maintenance-window')});
    }

    async showUrlPopup(url) {
        this.runOnFree(() => { this.showDialog('openurl-popup', {url: url})});
    }

    async showWebshopPopup(url) {
        this.runOnFree(() => { this.showDialog('webshop-framed', {url: url})});
    }

    async showWebshopPopup(url, sku) {
        this.runOnFree(() => { this.showDialog('webshop-framed', {url: url, sku: sku})});
    }

    async hideMaintenanceWindow() {
        this.hideWindow('maintenance-window');
    }

    async showUnstableConnection() {
        var elem = e(Stack, {direction: "column", justifyContent: "center", alignItems: "center"},
            e(Stack, {direction: "row", spacing: 3, alignItems: "center"}, e(WifiOffIcon, {fontSize: "large"}), e(Typography, {variant: "body1"}, "Unstable connection, please try another network")),

            FRONT_PROJECT == "sb"?
            e(Typography, {variant: "h6", marginTop: "10px"}, "Download the game for:"):null,

            FRONT_PROJECT == "sb"?
            e(Stack, {direction: "row", spacing: 2, justifyContent: "center", alignItems: "center"},
                e(IconButton, {onClick: () => { window.open("https://store.steampowered.com/app/2080820"); }}, e("img", {src: "f/icon_steam.png", style: {width: "32px"}})),
                e(IconButton, {onClick: () => { window.open("https://store.playstation.com/en-us/concept/10007616/"); }}, e("img", {src: "f/icon_playstation.png", style: {width: "32px"}})),
                e(IconButton, {onClick: () => { window.open("https://www.microsoft.com/store/productid/9MWVJDG505W4"); }}, e("img", {src: "f/icon_xbox.png", style: {width: "32px"}})),
                e(IconButton, {onClick: () => { window.open("https://play.google.com/store/apps/details?id=com.ultrahorse.squadblast&utm_source=beta_site"); }}, e("img", {src: "f/icon_google-play.png", style: {width: "32px"}})),
                e(IconButton, {onClick: () => { window.open("https://apps.apple.com/app/squadblast/id6444757950"); }}, e("img", {src: "f/icon_app-store.png", style: {width: "32px"}})),
            ):null


        );

        await this.showToast( elem );

//        await this.showToast( e(Stack, {direction: "row", spacing: 3, alignItems: "center"}, e(WifiOffIcon, {fontSize: "large"}), e(Typography, {variant: "body1"}, "Unstable connection, please try another network")) );
    }
/*
    async preloadLogin() {
        if(this._loginDialog == null) {
            try{
                this._loginDialog = await import('./popups/login-popup.js');
                this._loginDialog.mount("login_popup");
            }catch(error) {
                window.Main.handleWindowError('login-popup', error)
            }
        }
    }*/
}