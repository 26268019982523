import { GameStream } from "./default/stream-sdk-19";

export class ConnectorDefault
{
    constructor(options) {
        options.video = options.video || {};

        if(parser.getOS().name == "iOS" || parser.getBrowser().name == "Safari" || parser.getDevice().type == "smarttv" ) {
            options.video.preferred_decoder_codecs = ["H264", "VP8", "VP9", "AV1"];
        }else{
            options.video.preferred_decoder_codecs = ["AV1", "VP9", "H264", "VP8"];
        }

        this._stream = new GameStream(options);
    }

    getVideoID() {
        return this._stream._videoID;
    }

    getAudioID() {
        return this._stream._audioID;
    }

    getDimensions() {
        return this._stream._dimensions;
    }

    sendData(channelName, data) {
        this._stream.sendData(channelName, data);
    }

    setInputEnabled(enabled) {
        this._stream.setInputEnabled(enabled);
    }

    getInputEnabled() {
        return this._stream.getInputEnabled();
    }

    rotate(orientation, force=false, ignore_dim=false, vertical_game=false) {
        this._stream.rotate(orientation, force, ignore_dim, vertical_game);
    }

    isplaystarted() {
        return this._first_frame; asdf
    }

    getCurrentOrientation() {
        return this._stream.getCurrentOrientation();
    }

    _onResize() {
        this._stream._onResize();
    }

    disconnect() {
        this._stream.disconnect();
    }

    connect() {
        this._stream.connect();
    }

    _sendControlMessageQueue(action, args) {
        this._stream._webrtcManager._sendControlMessageQueue(action, args);
    }

    _onPointerEvent(evt) {
        this._stream._onPointerEvent(evt);
    }

    _sendInputEvent(evt, data) {
        this._stream._sendInputEvent(evt, data);
    }

    _onMouseWheel(evt) {
        this._stream._onMouseWheel(evt);
    }

    _requestFullscreen() {
        this._stream._requestFullscreen();
    }

    exitFullscreen() {
        this._stream.exitFullscreen();
    }

    startFPSIncrease() {
        //this._render.startFPSIncrease();
    }

    stopFPSIncrease() {
        //this._render.stopFPSIncrease();
    }

    needsTimeout() {
        return true;
    }
}

// current_stream = stream;