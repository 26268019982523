"use strict";

import { get } from 'lodash';
import {send_ev, reloadPopup} from './main.js';
import { ProfileSocket } from './profile-socket.js';
import { Strings } from './string.js';

export class Profile
{
    constructor(server_url, default_game) {
        this._server_url = server_url;
        this._default_game = default_game;
        this._ready = false;
        this._profile = null;
        this._savingQueue = 0;
        this._profile_id = null;
        this._triggerSavingAgain = false;
        this._flushRequired = false;
        this._propertyListeners = [];
        this._profile_socket = null;
        this._changed_items = [];
    }

    findGetParameter(parameterName) {
        var result = null,
            tmp = [];
        var items = location.search.substr(1).split("&");
        for (var index = 0; index < items.length; index++) {
            tmp = items[index].split("=");
            if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
        }
        return result;
    }

    async fetch(onready) {
        try{
            var profile_id = localStorage.getItem("profile_id");

            var restore_pwa = this.findGetParameter("restore_pwa");
            if( restore_pwa != null ) {
                if(restore_pwa != profile_id) {
                    localStorage.setItem("profile_id", restore_pwa);
                    localStorage.setItem("restore_id", restore_pwa);

                    profile_id = restore_pwa;
                }
            }

            // authenticate with token
            var token = this.findGetParameter("token");
            var first_login = false;
            if(token != null) {
                first_login = true;
            }
            if(token == null) {
                token = localStorage.getItem("jwt_token");
            }
            if( token != null ) {
                localStorage.setItem("jwt_token", token);

                const token_answer = await fetch(this._server_url+"api/profile/token/"+token+"/"+profile_id + "/" + this._default_game, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "X-region": region_route,
                        "X-tag": region_tag
                    }
                });
                const token_json = await token_answer.json();
                var continue_play = true;

                if('status' in token_json) {
                    if(token_json['status'] == 'fail') {
                        // wrong token
                        localStorage.removeItem("jwt_token");
                        this.fetch(onready);

                        window.ui.showLogin(false);
                        return;
                    }
                }
                if(continue_play) {
                    localStorage.setItem("profile_id", token_json['profile_id']);
                    profile_id = token_json['profile_id'];
                    this._profile = token_json;
                }
            }else{
                // check if we have profile
                if(profile_id == null || profile_id == "null" || profile_id == undefined || profile_id == "undefined") {
                    // register new
                    const profile_new = await fetch(this._server_url+"api/profile/new/" + this._default_game, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            "X-region": region_route,
                            "X-tag": region_tag
                        }
                    });
                    const profile_json = await profile_new.json();
                    localStorage.setItem("profile_id", profile_json['profile_id']);
                    profile_id = profile_json['profile_id'];

                    this._profile = profile_json;
                }else{
                    // load from server
                    const profile_stored = await fetch(this._server_url+"api/profile/load/"+profile_id + "/" + this._default_game, {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            "X-region": region_route,
                            "X-tag": region_tag
                        }
                    });

                    // check if 404
                    if(profile_stored.status == 404) {
                        // register new
                        const profile_new = await fetch(this._server_url+"api/profile/new/" + this._default_game, {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                                "X-region": region_route,
                                "X-tag": region_tag
                            }
                        });
                        const profile_json = await profile_new.json();
                        localStorage.setItem("profile_id", profile_json['profile_id']);
                        profile_id = profile_json['profile_id'];

                        this._profile = profile_json;
                    }else{
                        const profile_json = await profile_stored.json();
                        this._profile = profile_json;
                    }
                }
            }

            this._profile_socket = new ProfileSocket(this);
            this._profile_socket.connect();

            this._profile_id = profile_id;
            this._ready = true;

            window.play_server.setDefaultServer(this._profile['public_data']['game_server'])
            
            onready();

            this.propertyChanged();
            
            setInterval(() => {
                if(this._flushRequired) {
                    this.queueSaving();
                    this._flushRequired = false;
                }
            }, 30000); // flush settings once 30s if they are not critical

            console.log("Is first", first_login)

            if(first_login) {
                var phone = this.getPhone();
                console.log("Checks", this._default_game, phone, localStorage.getItem("user_id_pay"));
                if(this._default_game == "SquadBlast" && phone != null && phone != "" && localStorage.getItem("user_id_pay") != null) {
                    var link = fronturl + "restore?bonus=" + this.getProfileID() + "&game=" + this._default_game + "&user_id=" + localStorage.getItem("user_id_pay");
            
                    var response = await fetch(fronturl+"u/", {
                        method: "POST",
                        headers: { "Content-Type": "application/json" },
                        body: JSON.stringify({"url": link}),
                    });
            
                    if(response.status != 200) {
                        console.log("Failed to shorten url", response);
                        return;
                    }
            
                    const res_json = await response.json();
            
                    var shortened_url = fronturl + "u/" + res_json['id'];
                    
                    var phonenumber = phone;
                    var message = "Claim your FREE gift: " + shortened_url + " 🎁";

                    var sms_obj = {
                        "phone": phonenumber,
                        "message": message
                    }

                    var response = await fetch(serverurl_pay+"api/paysms/", {
                        method: "POST",
                        headers: { "Content-Type": "application/json" },
                        body: JSON.stringify(sms_obj),
                    });
                }
            }
        }catch(e) {

            console.log(e);
            // spawn error
            send_ev("errorprofile_fetch", {'message': e.message, 'name': e.name, 'full': JSON.stringify(e, Object.getOwnPropertyNames(e))}, true);

            reloadPopup();
        }
    }

    log(mode, data, time) {
        if(this._profile_socket != null && this._profile_socket.isConnected()) {
            this._profile_socket.log(mode, data, time);
            return true;
        }
        return false;
    }

    logCameIn(mode, data, time) {

    }

    isReady() {
        return this._ready;
    }

    saveId(game_id, save_id) {
        var current_save_id = this.getItem(game_id + "_internal_id");

        mylogi("Already saved id: ", current_save_id, " incoming save id: ", save_id);

        if(current_save_id != "" && save_id != "" &&
                current_save_id != undefined && save_id != undefined &&
                current_save_id != null && save_id != null) {
            if(current_save_id != save_id) {
                mylogi("Detected corrupted save");

                send_ev("wrong_save", {'current': current_save_id, 'incoming': save_id});
            }else{
                mylogi("Save id is proper one");
            }
        }

        if(current_save_id == "" || current_save_id == undefined || current_save_id == null) {
            this.setItem(game_id + "_internal_id", save_id);
            mylogi("Saved new internal id");
        }else{
            // notify container of proper id
//            window.Main.sendToGame({"eventType": "save_id", "id": current_save_id})
        }
    }

    playingAt(game_id, host) {
        var game_plays = this.getItem("game_plays");
        if(game_plays == null) {
            game_plays = [];
        }
        for(var i=0; i<game_plays.length; i++) {
            if(game_plays[i]['game_id'] == game_id) {
                game_plays.splice(i, 1);
                break;
            }
        }

        game_plays.push({
            'game_id': game_id,
            'host': host,
            'time': window.serverTime
        });
        this.setItem("game_plays", game_plays);
    }

    getLastPlayServer(game_id) {
        var game_plays = this.getItem("game_plays");
        if(game_plays == null) {
            return null;
        }
        for(var i=0; i<game_plays.length; i++) {
            if(game_plays[i]['game_id'] == game_id) {
                mylogi("Resuming on the same server: " + game_plays[i]['host']);
                return game_plays[i]['host'];
            }
        }
        return null;
    }

    async claim(bonus_id, amount, sender_nick) {
        // close chat
        if(window.Main.isChatOpened()) {
            window.Chat.close();
        }

        const bonus_claim = await fetch(this._server_url+"api/profile/claim/"+this._profile_id+"/"+bonus_id, {
            method: "GET"
        });
        const bonus_json = await bonus_claim.json();

        send_ev("bonus_claim", {'bonus_id': bonus_id, 'amount': amount});

        var bonus = {
            "eventType": "bonus",
            "bonusId": bonus_json["bonus"]['bonus_id'],
            "bonusUnique": bonus_json["bonus"]['bonus_unique'],
            "bonusTime": bonus_json["bonus"]['bonus_time'],
            "profileId": bonus_json["bonus"]['profile_id'],
            "secret": bonus_json["bonus"]['secret'],
        }
        window.Main.sendToGame(bonus);

        window.ui.showToast(Strings['toast.bonus.claimed']);
        window.Chat.bonus_claimed(bonus, sender_nick);

        this.claimAsSB();
    }

    async claimAsSB() {
        if(localStorage.getItem("user_id_pay") == null) {
            return;
        }
        if(window.Main.getCurrentGame() != "SquadBlast") {
            return;
        }

        const bonus_claim = await fetch(serverurl_pay+"api/bonus/SquadBlast/"+localStorage.getItem("user_id_pay") + "/", {
            method: "GET"
        });
    }

    getSessionTime(game_id) {
        if(this._ready == false) {
            throw new Error("Profile not loaded yet");
        }

        if(('playtimeex' in this._profile['private_data']) && (game_id in this._profile['private_data']['playtimeex'])) {
            return this._profile['private_data']['playtimeex'][game_id]['total'];
        }else{
            return 0;
        }
    }

    getSessionTimeToday(game_id) {
        if(this._ready == false) {
            throw new Error("Profile not loaded yet");
        }

        if(('playtimeex' in this._profile['private_data']) && (game_id in this._profile['private_data']['playtimeex'])) {
            return this._profile['private_data']['playtimeex'][game_id]['today'];
        }else{
            return 0;
        }
    }

    getSessionTimeTodayMax(game_id) {
        if(this._ready == false) {
            throw new Error("Profile not loaded yet");
        }

        if(window.login.isLoggedIn()) {
            return 60*60;
        }

        return 30*60;
    }

    getPhone() {
        if(this._ready == false) {
            throw new Error("Profile not loaded yet");
        }

        return this._profile['private_data']['phone'];
    }

    getBalance() {
        if(this._ready == false) {
            throw new Error("Profile not loaded yet");
        }

        return this._profile['private_data']['balance'];
    }

    addPropertyListener(listener) {
        this._propertyListeners.push(listener);
    }

    propertyChanged() {
        this._propertyListeners.forEach((x) => {
            x();
        });
    }

    getProfileID() {
        if(this._ready == false) {
            return null;
        }
        return this._profile_id;
    }

    getRestoreURL() {
        return fronturl + "restore?restore=" + window.profile.getProfileID() + "&game=" + window.Main.getCurrentGame();
    }

    getItem(key) {
        if(this._ready == false) {
            throw new Error("Profile not loaded yet");
        }

        try {
            return this._profile["public_data"][key];
        } catch {
            return null;
        }
    }

    setItems(items, flush=true) {
        if(this._ready == false) {
            throw new Error("Profile not loaded yet");
        }

        for(const key in items) {
            if(this._changed_items.includes(key) == false) {
                this._changed_items.push(key);
            }
            this._profile["public_data"][key] = items[key];
        }

        if(flush) {
            this.queueSaving();
        }else{
            this._flushRequired = true;
        }
    }

    setItem(key, value, flush=true) {
        var obj = {};
        obj[key] = value;
        this.setItems(obj, flush);
    }

    isExtendedPlaytime(game_id) {
        if("extended" in this._profile["private_data"]) {
            for (var i = 0; i < this._profile["private_data"]["extended"].length; i++) {
                var g = this._profile["private_data"]["extended"][i];
                if(g['game_id'] == game_id) {
                    return true;
                }
            }
        }
        return false;
    }

    isOvertime(game_id, seconds) {
        if(this._ready == false) {
            throw new Error("Profile not loaded yet");
        }

        if(seconds <= 3600) {
            return false;
        }
        if("extended" in this._profile["private_data"]) {
            for (var i = 0; i < this._profile["private_data"]["extended"].length; i++) {
                var g = this._profile["private_data"]["extended"][i];
                if(g['game_id'] == game_id) {
                    return false;
                }
            }
        }

        return true;
    }

    async versionNow(version) {
        if(this._profile != null && this._profile != undefined) {
            if(version > this._profile["version"]) {
                mylogi("Profile loading in versionNow");

                const profile_stored = await fetch(this._server_url+"api/profile/load/"+this._profile_id, {
                    method: "GET"
                });

                // if code 200
                if(profile_stored.status == 200) {
                    const profile_json = await profile_stored.json();
                    this._profile = profile_json;
                    
                    this.propertyChanged();
                    window.Main.checkOnlyTab();
                }
            }
        }
    }

    socketsActive() {
        return this._profile_socket != null && this._profile_socket.isConnected();
    }

    async syncServerField(version, storage, field, value) {
        this._profile[storage=="private"?"private_data":"public_data"][field] = value;

        this._profile['version'] = version;

        this.propertyChanged();
        window.Main.checkOnlyTab();
    }

    async syncFields(version, data) {
        for (const k in data) {
            this._profile["public_data"][k] = data[k];
        }
        this._profile['version'] = version;

        this.propertyChanged();
        window.Main.checkOnlyTab();
    }

    isSocketMode() {
        return this._profile_socket != null && this._profile_socket.isConnected();
    }

    async queueSaving() {
        if(this.isSocketMode()) {
            // push immediatly
            var arr = this._changed_items.slice();
            this._changed_items = [];
            this._flushRequired = false;
            var profile_json = await this._profile_socket.saveFields(this._profile['version'], this._profile["public_data"], arr);

            // version -> hash check
            if(profile_json['status'] == "ok") {
                this._profile['version'] = profile_json['version'];
            }
            return;
        }

        if(this._savingQueue != 0 || this._triggerSavingAgain) {
            if(this._savingQueue == 2) {
                this._triggerSavingAgain = true;
            }
            return;
        }

        this._savingQueue = 1;

        setTimeout(async () => {
            this._savingQueue = 2;

            // duplicate to profile sockets
            var profile_json = null;
            if(this._profile_socket != null && this._profile_socket.isConnected()) {
                var arr = this._changed_items.slice();
                this._changed_items = [];
                profile_json = await this._profile_socket.saveFields(this._profile['version'], this._profile["public_data"], arr);
            }else{
                const profile_save = await fetch(this._server_url+"api/profile/save/"+this._profile_id+"/"+this._profile['version'], {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify(this._profile["public_data"])
                });
                profile_json = await profile_save.json();
            }

            if(profile_json['status'] == "reload") {
                this._profile = profile_json['profile'];
                mylogi("Profile loading in reload on save");

                this.propertyChanged();
            }
            if(profile_json['status'] == "ok") {
                this._profile['version'] = profile_json['version'];
            }
    
            this._savingQueue = 0;
            this._flushRequired = false;

            if(this._triggerSavingAgain) {
                this._triggerSavingAgain = false;
                this.queueSaving();
            }
        }, 100);
    }
}