'use strict';

import { PayXsolla } from "./pay-xsolla";

var ws_sku = null;
var init_url = null;

export class PayWS {
    constructor(payments) {
        this._payments = payments;
        this._sku = null;

        this._payments_xs = new PayXsolla(this._payments);
    }

    async pay_now(pay_obj)
    {
        console.log(pay_obj, pay_obj.product_id);

        ws_sku = pay_obj.product_id;
        init_url = pay_obj.init_url;

        PayWS.showWebshop();
    }

    async confirmPurchase(invoice_id)
    {

    }

    static webshopURLS = {};

    static async showWebshop(fromclick = false) {
        var dev = parser.getDevice().type;
        var another_tab = false;
        if( dev == "mobile" || dev =="tablet" ) {
            another_tab = true;
        }

        // check if webshop url is already in the cache
        if(PayWS.webshopURLS[window.Main.getCurrentGame()] != null) {
            var url = PayWS.webshopURLS[window.Main.getCurrentGame()];
            
            if(init_url != null) {
                url = init_url;
                init_url = null;
            }

            if(another_tab) {
                if(fromclick) {
                    window.open(url, '_blank');
                }else{
                    window.Main.open_rul(url);
                }
            }else{
                window.ui.showWebshopPopup(url, ws_sku);
            }
            ws_sku = null;
            return;
        }

        // query for file with ws url
        const wsconfig = await fetch(serverurl_pay+"api/pay/webshop/" + window.Main.getCurrentGame() + "/", {
            method: "GET"
        });

        // check http code
        if(wsconfig.status == 404) {
            alert("Webshop not configured");
            return;
        }

        const wsconfig_json = await wsconfig.json();

        var url = wsconfig_json["webshop"];

        if(init_url != null) {
            url = init_url;
            init_url = null;
        }

        if(another_tab) {
            if(fromclick) {
                window.open(url, '_blank');
            }else{
                window.Main.open_rul(url);
            }
        }else{
            window.ui.showWebshopPopup(url, ws_sku);
            ws_sku = null;
        }

        PayWS.webshopURLS[window.Main.getCurrentGame()] = url;
    }
} 