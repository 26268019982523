"use strict";

export class ProfileSocket
{
    constructor(profile) {
        this._profile = profile;
        this._server_url = serverurl_websocket;
        this._socket = null;
        this._request_id = 0;
        this._results_callbacks = [];
        this._reconnect_num = 0;
        this._connected = false;

        this.onSocketOpen = this.onSocketOpen.bind(this);
        this.onSocketClose = this.onSocketClose.bind(this);
        this.onSocketError = this.onSocketError.bind(this);
        this.onSocketMessage = this.onSocketMessage.bind(this);

        this.onSystemBlock = this.onSystemBlock.bind(this);

        PubSub.subscribe("system.block", this.onSystemBlock);
    }

    isConnected() {
        return this._socket != null && this._connected == true;
    }

    connect() {
        this._socket = new WebSocket(this._server_url);

        this._socket.addEventListener('open', this.onSocketOpen);
        this._socket.addEventListener('close', this.onSocketClose);
        this._socket.addEventListener('message', this.onSocketMessage);
        this._socket.addEventListener('error', this.onSocketError);
    }

    disconnect() {
        this._socket.close();
        this._socket = null;
        this._connected = false;
    }

    onSystemBlock() {
        if(this._socket != null) {
            this.disconnect();
        }
    }

    onSocketOpen() {
        this._reconnect_num = 0;
        this.sendMessage("auth", {"profile_id": this._profile.getProfileID()});
        this._connected = true;

        PubSub.publish('socket.connected');
    }

    onSocketClose() {
        PubSub.publish('socket.closed');

        if(this._socket != null) {
            this._reconnect_num = this._reconnect_num + 1;
            if(this._reconnect_num > 5) {
                this._reconnect_num = 5;
            }
            this._connected = false;

            setTimeout(() => {
                this.connect();
            }, 5000 * this._reconnect_num);
        }
    }

    onSocketError() {
    }

    onSocketMessage(event) {
        var data = event.data;
        var json = JSON.parse(data);

        // incoming message
        if(json['type'] == "sync") {
            this._profile.versionNow(json['version']);
        }
        if(json['type'] == "sync_fields") {
            this._profile.syncFields(json['version'], json['data']);
        }
        if(json['type'] == "sync_server_fields") {
            this._profile.syncServerField(json['version'], json['storage'], json['field'], json['value']);
        }
        if(json['type'] == "log") {
            this._profile.logCameIn(json['mode'], json['data'], json['time']);
        }
        if(json['type'] == "push_log") {
            for(var i=0; i<json['logs'].length; i++) {
                this._profile.logCameIn(json['logs'][i]['mode'], json['logs'][i]['data'], json['logs'][i]['time']);
            }
        }
        if(json['type'] == "payment") {
            if(json.state == 4) {
                // payment was successfull - close webshop window
                PubSub.publish('payment.done');

                window.ui.showToast("✅ Payment completed");
            }
        }

        // requests callbacks
        if('request_id' in json) {
            var request_id = json['request_id'];
            for(var i=0; i<this._results_callbacks.length; i++) {
                if(this._results_callbacks[i]['request_id'] == request_id) {
                    this._results_callbacks[i]['resolve'](json);
                    this._results_callbacks.splice(i, 1);
                    break
                }
            }
        }
    }

    sendMessage(message, data) {
        this._request_id = this._request_id + 1;
        this._socket.send(JSON.stringify(Object.assign({"request_id": this._request_id, "type": message}, data)));
        return this._request_id;
    }

    async sendWithResult(message, data) {
        return new Promise((resolve, reject) => {
            var request_id = this.sendMessage(message, data);

            this._results_callbacks.push({"request_id": request_id, "resolve": resolve});
        });
    }

    async saveProfile(version, profile_public_data) {
        var result = await this.sendWithResult("save_profile", {"version": version, "data": profile_public_data});
        return result;
    }

    async saveFields(version, profile_public_data, changed_fields) {
        var data = {};

        for(var i=0; i<changed_fields.length; i++) {
            var key = changed_fields[i];

            data[key] = profile_public_data[key];
        }

        var result = await this.sendWithResult("save_fields", {"data": data});
        return result;
    }

    async log(mode, data, time) {
        this.sendMessage("log", {"mode": mode, "data": data, "time": time});
    }
}