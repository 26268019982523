'use strict';

import { PayStripe } from "./pay-stripe";
import { PayXsolla } from "./pay-xsolla";
import { PayWS } from "./pay-ws";

export class Payments {
    constructor(payment_type) {
        this._payment_type = payment_type;
        this._paying = false;

        if(payment_type == "stripe") {
            this._payments = new PayStripe(this);
            this._payments_stripe = this._payments;
        }
        if(payment_type == "xsolla") {
            this._payments = new PayXsolla(this);
            this._payments_xsolla = this._payments;
        }
        if(payment_type == "ws") {
            this._payments = new PayWS(this);
            this._payments_ws = this._payments;
            this._payments_xsolla = new PayXsolla(this);
        }
        if(payment_type == "select") {
            this._payments_stripe = new PayStripe(this);
            this._payments_xsolla = new PayXsolla(this);
            this._payments_ws = new PayWS(this);
        }

        mylogi("!!! INIT PAY WITH", payment_type);
    }

    async pay_xsolla_force(sku, payment_id, developer_payload)
    {
        if(this._payments_xsolla != null) {
            var pay_obj = {"game_id": window.Main.getCurrentGame(),
                        "payment_id": payment_id,
                        "game_save_id": window.Main.getCurrentSaveID(),
                        "product_id": sku,
                        "developer_payload": developer_payload,
                        "tokens": isFlag('tokens')};
            
            this._payments_xsolla.pay_now(pay_obj);
        }
    }

    activatePayment(payment_type) {
        //this._payment_type = payment_type;

        if(payment_type == "stripe") {
            this._payments = this._payments_stripe;
        }
        if(payment_type == "xsolla") {
            this._payments = this._payments_xsolla;
        }
        if(payment_type == "ws") {
            this._payments = this._payments_ws;
        }
    }

    do_payment(sku, payment_id, developer_payload, init_url=null)
    {
        mylogi("Paying for " + sku + " id: " + payment_id + " with payload: " + developer_payload + " backend " + this._payment_type);

        if(developer_payload != "") {
            // try to parse as json
            try {
                var dev_payload = JSON.parse(developer_payload);
                if('user_id' in dev_payload) {
                    localStorage.setItem("user_id_pay", dev_payload['user_id']);
                    console.log("User id set to " + dev_payload['user_id']);
                }
            }catch(e) {
            }
        }

        this._paying = true;

        if(window.Main.current_stream != null && document.fullscreenElement != null) {
            window.Main.current_stream.exitFullscreen();
        }

        if( 'pay_count' in window.profile._profile['private_data'] &&
            window.Main.getCurrentGame() in  window.profile._profile['private_data']['pay_count'] &&
            window.profile._profile['private_data']['pay_count'][window.Main.getCurrentGame()] >= 3333) {
            setTimeout(function() {
                // notify game
                window.ui.showToast("Payments not available, check later");
                window.Main.sendToGame({
                    paymentID: payment_id,
                    state: 3
                });
            }, 1000);
            return;
        }

        window.Main.send_ev("bank_open", {'sku': sku});

        if (typeof fbq !== "undefined") { 
            fbq('trackCustom', 'bank_open');
        }

        // save active payment
        var pay_obj = {"game_id": window.Main.getCurrentGame(),
                        "payment_id": payment_id,
                        "game_save_id": window.Main.getCurrentSaveID(),
                        "product_id": sku,
                        "developer_payload": developer_payload,
                        "tokens": isFlag('tokens'),
                        'init_url': init_url};
        window.profile.setItem("activepay_" + window.Main.getCurrentGame(), JSON.stringify(pay_obj));

        this._payments.pay_now(pay_obj);
    }

    pay_now(sku, payment_id, developer_payload, init_url=null)
    {
        if(this._payment_type == "select") {
            window.ui.showSelectPayment(() => {
                this.do_payment(sku, payment_id, developer_payload, init_url);
            });
        }else{
            this.do_payment(sku, payment_id, developer_payload, init_url);
        }
    }

    paymentError(error) {
        var pay_obj = window.profile.getItem("activepay_" + window.Main.getCurrentGame());
        if(pay_obj != null && pay_obj != "null") {
            pay_obj = JSON.parse(pay_obj);
            this.cancelPayment(pay_obj);
            window.profile.setItem("activepay_" + window.Main.getCurrentGame(), null);
        }

        this._paying = false;
        window.ui.checkFreeQueue();

        myloge("Payment error: " + JSON.stringify(error));
    }

    paymentCancel(pay_obj) {
        $.ajax({
            url:serverurl_pay+"/api/pay/cancel/"+pay_obj['game_save_id']+"/"+pay_obj['game_id']+"/",
            type:"POST",
            success: function(){
            }
        });

        window.Main.sendToGame({
            paymentID: pay_obj['payment_id'],
            state: 3
        });
        
        this._paying = false;
        window.ui.checkFreeQueue();
    }

    paymentDone(billing) {
        if(billing["purchase"]["developer_payload"] == "extend_play") {
            window.Main.extendedPlay(billing["purchase"]);
        }else{
            if(billing["purchase"]["developer_payload"]  == "starter_pack") {
                window.Main.starterGot(billing["purchase"]);
            }else{
                window.Main.sendToGame({
                    productID: billing["purchase"]["product_id"],
                    paymentID: billing["purchase"]["payment_id"],
                    developerPayload: billing["purchase"]["developer_payload"],
                    purchasePriceAmount: billing["purchase"]["purchase_amount"],
                    purchasePriceCurrency: billing["purchase"]["purchase_currency"],
                    purchaseTime: Math.floor((new Date(billing["purchase"]["changed_at"])).getTime()/1000.0),
                    isConsumed: false,
                    secret: billing["purchase"]["secret"],
                    state: 4
                });

                if (typeof fbq !== "undefined") { 
                    fbq('track', 'Purchase', { 
                        value: billing["purchase"]["purchase_amount"],
                        currency: billing["purchase"]["purchase_currency"],
                        content_name: billing["purchase"]["product_id"],
                        content_type: 'inapp',
                        content_ids: billing["purchase"]["product_id"],
                        num_items: 1
                    });
                }
            }
        }
        
        window.profile.setItem("activepay_" + billing["purchase"]["game_id"], null);

        this._paying = false;
        window.ui.checkFreeQueue();
    }

    isPaying() {
        return this._paying;
    }

    async sessionLoaded() {
        var pay_obj = window.profile.getItem("activepay_" + window.Main.getCurrentGame());
        if(pay_obj != null && pay_obj != "null" && pay_obj != undefined) {
            pay_obj = JSON.parse(pay_obj);
            
            mylogd("Checking payment status");

            const unfinished = await fetch(serverurl_pay+"api/pay/unconsumed/"+window.Main.getCurrentSaveID()+"/" + window.profile.getProfileID() + "/", {
                method: "GET"
            });
            const unfinished_json = await unfinished.json();

            var found = null;
            var unfound = [];
            for(var u in unfinished_json['purchases']) {
                if(u['payment_id'] == pay_obj["payment_id"]) {
                    found = unfinished_json['purchases'][u];
                }else{
                    unfound.push(u);
                }
            }

            if(found) {
                mylogi("Found active payment in ready - sending to game", found);

                this.paymentDone({'purchase': found});
            }else{
                mylogi("Active payment can be resetted: " + pay_obj['payment_id']);
                window.Main.sendToGame({
                    paymentID: pay_obj['payment_id'],
                    state: 3
                });
                window.profile.setItem("activepay_" + window.Main.getCurrentGame(), null);
            }

            mylogi("Cancelling " + unfound.length + " payments");
        }
    }

    consumeInapp(payment_id, callback = null)
    {
        var save_id = window.Main.getCurrentSaveID();

        $.ajax({
            url:serverurl_pay+"/api/pay/consume/"+save_id+"/"+payment_id+"/",
            type:"POST",
            success: function(data){
                if(callback != null) {
                    callback(data);
                }else{
                    if(data['status'] == "ok") {
                        window.Main.sendToGame({paymentID: payment_id,
                                    state: 5});
                    }else{
                        window.Main.sendToGame({paymentID: payment_id,
                                    state: 2});
                    }
                }
            }
        });
    }

    cancelPayment(pay_obj)
    {
        setTimeout(function() {
            // notify game
            window.Main.sendToGame({
                paymentID: pay_obj['payment_id'],
                state: 3
            });
        }, 5000);

        $.ajax({
            url:serverurl_pay+"/api/pay/cancel/"+pay_obj['game_save_id']+"/"+pay_obj['payment_id']+"/",
            type:"POST",
            success: function(){
            }
        });
    }

}